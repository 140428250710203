import * as React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

export function ContentInPolishLanguageAlert(props: {
  what: string;
  isOrAre: "is" | "are";
}) {
  const { language } = useI18next("index");

  return (
    language !== "pl" && (
      <div className="alert alert-warning mb-5" role="alert">
        <h4 className="alert-heading">
          <img
            src="/images/languages/en.png"
            alt="en"
            style={{
              width: 22,
              position: "relative",
              top: -2,
              marginRight: 10,
            }}
          />{" "}
          Dear user,
        </h4>
        <p>
          We would like to inform you that {props.what} {props.isOrAre} written
          in the Polish language. We recommend interpreting the content in
          Polish to avoid any misunderstandings. If you need to read{" "}
          {props.what}, we encourage you to use a translator or text translation
          tools that can help you translate them into your preferred language.
        </p>
        <p>Thank you for your understanding.</p>

        <p>
          Sincerely,
          <br />
          Inspecto Team
        </p>
      </div>
    )
  );
}
