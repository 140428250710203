import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { ContentInPolishLanguageAlert } from "../components/ContentInPolishLanguageAlert";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const Gdpr: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout
      seoTitle={t("gdprLink")}
      location={location}
      seoDescription={t("gdprLink")}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <section className="page-header">
              <ContentInPolishLanguageAlert
                what="the GDPR information clause"
                isOrAre="is"
              />
              <h2>Klauzula Informacyjna RODO</h2>
              <h5>Wersja z dnia 29.11.2021 r</h5>
              <p>
                <strong>1.</strong>&nbsp;Administratorem Pani/Pana danych
                osobowych jest
                <br />
                INSPECTO Sp. z o.o.
                <br />
                ul. Świętokrzyska 30 lok. 63,
                <br />
                00-116 Warszawa,
                <br />
                KRS 0000740756;
                <br />
                NIP: 5771986272.
                <br />
                <strong>2.</strong>&nbsp;W sprawach z zakresu ochrony danych
                osobowych mogą Państwo kontaktować się ze Specjalistą Inspecto
                Sp. z o.o. do spraw ochrony danych pod adresem e-mail:
                biuro@getinspecto.pl
                <br />
                <strong>3.</strong>&nbsp;Pani/Pana dane osobowe będą
                przechowywane w zakresie oraz przez okres niezbędny do
                realizacji poniższych cel&oacute;w, a po tym czasie przez okres
                oraz w zakresie wymaganym przez przepisy powszechnie
                obowiązującego prawa:
                <br />
                a) w celu niezbędnym do świadczenia Usług świadczonych przez
                Inpecto Sp. z o.o. zgodnie z art. 6 RODO pkt. 1 litera b, przez
                okres trwania Umowy oraz przez 14 dni po jej zakończeniu,
                <br />
                b) wypełnienia obowiązk&oacute;w prawnych zgodnie z art.6 RODO
                pkt. 1 litera c tj.
                <br />
                &bull; dla cel&oacute;w finansowo &ndash; podatkowych zgodnie z
                Ordynacją Podatkową oraz ustawą o rachunkowości przez okres 5
                lat, licząc od początku roku następującego po roku,
                kt&oacute;rego dokumenty dotyczą. Przetwarzane będą dane podane
                do faktury;
                <br />
                &bull; dla cel&oacute;w związanych z roszczeniami wynikającymi z
                zawartej Umowy o świadczenie usług przez okres 3 lat zgodnie
                k.c. przetwarzane będą dane zawarte w Umowie;
                <br />
                c) w pozostałych przypadkach Pani/Pana dane osobowe przetwarzane
                są wyłącznie na podstawie wcześniej udzielonej zgody w zakresie
                i celu określonym w treści zgody.
                <br />
                <strong>4.</strong>&nbsp;W związku z przetwarzaniem danych w
                celach, o kt&oacute;rych mowa w pkt 3 odbiorcami Pani/Pana
                danych osobowych mogą być:
                <br />
                a) organy władzy publicznej oraz podmioty wykonujące zadania
                publiczne lub działające na zlecenie organ&oacute;w władzy
                publicznej, w zakresie i w celach, kt&oacute;re wynikają
                z&nbsp;przepis&oacute;w powszechnie obowiązującego prawa;
                <br />
                b) inne podmioty, kt&oacute;re na podstawie stosownych
                um&oacute;w zawartych z Inspecto Sp. z o.o. przetwarzają dane
                osobowe, dla kt&oacute;rych Administratorem jest Inspecto Sp. z
                o.o. tj. dostawcy usług finansowo- podatkowych, dostawcy usług
                hostingowych itp.;
                <br />
                c) inne podmioty, kt&oacute;re na podstawie stosownych
                um&oacute;w podpisanych z Inspecto Sp. z o.o. i/lub na wyraźne
                Pani/ Pana polecenie przetwarzają Pani/Pana dane osobowe;
                <br />
                d) inne podmioty, kt&oacute;re w wyniku realizacji zleceń
                otrzymanych od Inspecto Sp. z o.o. przetwarzają dane osobowe,
                dla kt&oacute;rych Administratorem jest Inspecto Sp. z o.o. tj.
                urzędy pocztowe i inni dostawcy usług pocztowych, Banki itp.
                <br />
                <strong>5.</strong>&nbsp;Osoba, kt&oacute;rej dane dotyczą ma
                prawo do:
                <br />
                a. dostępu do treści swoich danych oraz możliwości ich
                poprawiania, sprostowania na podstawie art. 15 i 16 RODO
                <br />
                b. ograniczenia przetwarzania oraz do przenoszenia swoich danych
                na podstawie art. 18 RODO z zastrzeżeniem przypadk&oacute;w, o
                kt&oacute;rych mowa w art. 18 ust. 2 RODO
                <br />
                c. prawo do usunięcia danych Państwa danych, z zastrzeżeniem
                przypadk&oacute;w o kt&oacute;rych mowa w art. 17 ust. 3 lit. b,
                d lub e RODO
                <br />
                d. Prawo do wniesienia sprzeciwu wobec przetwarzania Państwa
                danych, z zastrzeżeniem przypadk&oacute;w, o kt&oacute;rych mowa
                w art.21 RODO, gdyż podstawą prawną przetwarzania Pani/Pana
                danych osobowych jest art. 6 ust. 1 lit. c RODO
                <br />
                e. wniesienia skargi do organu nadzorczego w przypadku gdy
                przetwarzanie danych odbywa się &nbsp;z naruszeniem
                przepis&oacute;w powyższego rozporządzenia, tj. Prezesa Urzędu
                Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa
                <br />
                f. prawo do wycofania zgody na przetwarzanie z zastrzeżeniem
                postanowień art. 6 RODO pkt 1. litera b,c
              </p>

              <p>
                <strong>6.</strong>&nbsp;Ponadto Inspecto Sp. z o.o. informuje,
                iż w związku z przetwarzaniem Pani/Pana danych osobowych nie
                podlega Pan/Pani decyzjom, kt&oacute;re się opierają wyłącznie
                na zautomatyzowanym przetwarzaniu, w tym profilowaniu, o czym
                stanowi art. 22 og&oacute;lnego rozporządzenia o ochronie danych
                osobowych.
              </p>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gdpr;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
